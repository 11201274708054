import React from "react";
import { graphql } from "gatsby";
import {Flex, Container, Heading, SimpleGrid, Button, Box, Image} from "@chakra-ui/react";
import ProductCard from "../../components/productcard";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import usePrismicNewsReports from "../../hooks/usePrismicNewsReports";
import ConvertDate from "../../components/ConvertDate";
// import JivoSiteScript from "../../../JivoSiteScript";


const News = () => {
    const { language } = useTranslation();
    const data = usePrismicNewsReports();
    return (
        <Main>
            {/* <JivoSiteScript/> */}
            <Container maxW="7xl" mb="13.5rem">
                <Seo
                    title={data.conferenceTitle}
                    description={data.conferenceTitle}
                    link={`https://www.fsm.am/${language}/{t("news")}`}
                />
                <SmallNav>

                    <Link to="/news-reports">{data.linkText}</Link>
                    {data.conferenceTitle}

                </SmallNav>
                <Heading
                    fontFamily="Mardoto"
                    as="h2"
                    fontSize={{ base: "2xl", md: "3xl" }}
                    mt="1.25rem"
                    mb="3.81rem"
                >
                    {data.conferenceTitle}
                </Heading>
                <SimpleGrid columns={{ base: "1", md: "3" }} spacing={2}>
                    {data.conferences?.map((conference, idx) => (
                        <Box position="relative">
                            <Link to={`/conference/?id=${conference.id}`}>
                                <Image
                                    h="90%"
                                    w="100%"
                                    objectFit="cover"
                                    borderRadius="24px"
                                    src={conference.image}
                                />
                                <Box position="absolute" top="7px" left="10px">
                                    <ConvertDate date={conference.date}/>
                                </Box>
                            </Link>
                        </Box>
                    ))}
                </SimpleGrid>
            </Container>
        </Main>
    );
};

export default News;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
